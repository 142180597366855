import React from 'react';
import PropTypes from 'prop-types';
import "./css/event.css";
import amazonLogo from "./images/amazon-logo.png";
import plusSign from "./images/plus-sign.png"; //https://www.flaticon.com/free-icon/plus-sign-button_16057?k=1631672820467

/*
Changing based on window size
large: (window.innerWidth >= 700),
updateDimensions = () => {
        console.log(window.innerWidth);
        console.log(window.innerHeight);
        this.setState({large: window.innerWidth >= 700})
      };
      componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
      }
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }
*/

class Event extends React.Component {
    constructor() {
        super();
        this.state = {
            large: false,
            expanded: false,
        }
        this.expand = this.expand.bind(this);
        this.getButtonWrapperClass = this.getButtonWrapperClass.bind(this);
        this.getExpandedInfo = this.getExpandedInfo.bind(this);
    }

      getEventClass() {
        let name = ""; 
        name = name + "event ";  
        if(!this.state.large) {name = name + "small-event ";}  
        if(this.state.expanded) {name = name + "unround-bottom-borders ";}  
        return name;
      }

      getTitleClass() {
        let name = "event-title acknowledge-whitespace";
        if(this.props.event.src === undefined) {
            name = name + " no-image";
        }
        if(this.state.large) {
            return name +  " d-inline-block event-image-border";
        }
        return name +  " small-event-image-border";
      }

      expand() {
          this.setState({expanded: !this.state.expanded});
      }
      
      getButtonWrapperClass() {
          return (this.state.expanded) ? "d-inline plus-icon expanded-button" : "d-inline plus-icon"
      }

      getExpandedInfo() {
        var infoClass = "";
        if(!this.state.expanded) {
            infoClass += "collapsed ";
        } else {
            infoClass += "expanded ";
        }

        if(this.state.large) {
            infoClass += "expanded-info ";
        } else {
            infoClass += "small-expanded-info expanded-info ";
        }


        return (
        <div className={infoClass}> 
            <div className={"info-padding"}>
                <ul>
                    {this.props.event.descriptionPoints.map((bulletPoint) => <li> {bulletPoint} </li>)}
                    {this.getDemo()}
                </ul>
            </div>
            
        </div>);
        
      }

      getDemo() {
          if(this.props.event.demo !== undefined) {
            <link rel="stylesheet" href="/css/video-react.css" />
                return(
                    <React.Fragment>
                        <div>Demo: </div>
                        <video src={this.props.event.demo} width={"100%"} height="auto" controls/>  
                    </React.Fragment>
                    
              );
          }
      }


    render() { 
        return (
        <React.Fragment>
            <div className={this.getEventClass()}>
            <a href={(this.props.event.companyUrl === undefined) ? null : this.props.event.companyUrl} >
                    <div className={(this.state.large) ? "company-wrapper" : "small-company-wrapper"}>
                    <img className={(this.state.large) ? "event-image" : "small-event-image"} src={this.props.event.src} alt={this.props.event.companyName}/>
                    </div>
            </a>
            
            <p className={this.getTitleClass()}>
            {" "}
            {this.props.event.title}
                    {", "}
                    {this.props.event.date}
            </p> 

            <div className={this.getButtonWrapperClass()}>
            <button onClick={this.expand} aria-label={this.state.expanded ? "Collapse" : "Expand"}>
                <span></span>
                <span></span>
            </button>
            </div>
        </div>
        {this.getExpandedInfo()}
        </React.Fragment>
        
        );
    }
}
 
Event.propTypes = {
    event: PropTypes.object.isRequired,
}
export default Event;