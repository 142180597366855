import React from 'react';
import "./css/navbar.css";
import "./css/utility.css";
import {Link} from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import EmailPopover from './emailPopover';
import NavbarToggle from 'react-bootstrap/esm/NavbarToggle';
import PropTypes from 'prop-types';

class Navbar extends React.Component {
    constructor() {
        super();
        this.state = {
            large: (window.innerWidth >= 763),
            email: "mailto:antonio.aldama@icloud.com?subject=[Company] Interested &body=Hello Antonio, %0d%0a %0d%0a We at [Company] are interested in your skills. %0d%0a %0d%0a Regards, %0d%0a [Name]",
            modalShown: false,
        }
    }

    handleClose = () => {this.setState({modalShown: false})};
    handleShow = () => {this.setState({modalShown: true})};

    /* Check if the window is small or large */
    updateDimensions = () => {
        console.log(window.innerWidth);
        console.log(window.innerHeight);
        this.setState({large: window.innerWidth >= 763})
      };
      componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
      }
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }

    getLinks() {
       if(this.state.large) { 
           return(
                <div className="float-right navbar-links ">
                    <div className="navbar-link d-inline"> 
                        <a className="navbar-anchor" href="#about"> About </a>  
                        <a className="navbar-anchor" href="#experience"> Experience </a>
                        <a className="navbar-anchor" href="#education"> Education </a>
                        <a className="navbar-anchor" href="#projects"> Projects </a>
                    </div>  
                    
                    
                </div>
           );
        }
    }
    render() { 
        return (
            <div className="my-navbar acknowledge-whitespace" > 
                <div className="brand d-inline">
                    {"Antonio "}    
                </div>
                <div className="brand-second d-inline">
                    {"Aldama"}    
                </div>
                
                    {this.getLinks()}
                    <a href="https://www.linkedin.com/in/antoalda" name="linkedin" className="navbar-linkedin-icon d-inline">
                        <FontAwesomeIcon icon={faLinkedin} size="3x"/>
                    </a>
                    
                    <EmailPopover 
                    companies={this.props.companies}
                    className="navbar-mail-icon"/>
            </div>
        );
        
    }
}
 // href={this.state.email} is what triggers the email

NavbarToggle.propTypes = {
    companies: PropTypes.array.isRequired
}
export default Navbar;