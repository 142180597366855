import React from 'react';
import "./css/projectCard.css";
import PropTypes from 'prop-types';


class ProjectCard extends React.Component {
    
    constructor() {
        super();
        this.state = {
            large: (window.innerWidth >= 906),
        }
        this.getProjectCard = this.getProjectCard.bind(this);
        this.getTextClass = this.getTextClass.bind(this);
        this.getTitle = this.getTitle.bind(this);
        this.getDescription = this.getDescription.bind(this);
        this.getVideo = this.getVideo.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
      }
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }

    updateDimensions = () => {
        this.setState({large: window.innerWidth >= 400})
      };

    getTextClass() {
        return (this.state.large) ? "education-text-large" : "education-text-small";
    }

    getTitle() {
        return (

            /*
            
           

            

            

                 

                <div className="project-title center-content">
                    <h3>
                        {this.props.project.title}
                    </h3>
                    
                </div> 


                

            <h3>
                <div className="project-title center-content">
                    {this.props.project.title}
                </div> 
            </h3>
            */
            
            <div role="heading" aria-level="3" className="project-title center-content">
                {this.props.project.title}
            </div>  
        
              
           
               
            

            
        );
    }

    getDescription() {
        return (
            <div className="project-text center-content">
                <p>
                    {this.props.project.description}
                </p>
                
            </div> 
        );
    }

    getVideo() {
        <link rel="stylesheet" href="/css/video-react.css" />
                return(
                    <div className="project-video">
                        <React.Fragment>
                            <video src={this.props.project.demo} border="none" outline="none" width={"100%"} height="320rem" controls/>  
                        </React.Fragment>
                    </div>
                    
                    
              );
    }

    getProjectCard() {
        

            return (
                <div className="project-box">
                    {this.getTitle()}
                    {this.getDescription()}
                    {this.props.project.demo === undefined ? (
                        <iframe title="bot" src="https://web.powerva.microsoft.com/environments/Default-8a198873-4fec-4e76-8182-ca479edbbd60/bots/new_bot_bf647f4da1134181b4a0e648b309c05c/webchat" style={{backgroundColor: "white", width: "calc(100% - 6.4rem)", height: "32rem", marginTop: "1.6rem", margin: "3.2rem", borderRadius: "0.5rem", border: "0.05rem solid #ccc"}} frameborder="0"></iframe>
                    ) : (
                        this.getVideo()
                    )
                    }
            </div>);

    }
    render() { 
        return(
            <div className="project-card">
                {this.getProjectCard()}
            </div>
                
        );
        
        
    }

}
 
// Title, Description and video required 
ProjectCard.propTypes = {
    project: PropTypes.object.isRequired, 
}
export default ProjectCard;
