import React from 'react';
import PropTypes from 'prop-types';
import "../css/aboutMe.css";
import myPic from "../images/myPicM.JPG";
import Resume from '../resume';
//<img className="my-pic" src={myPic} alt={""}/>
//<div className="blue">
//                blue
//            </div>
class AboutMe extends React.Component {

    constructor() {
        super();
        this.state = {
            large: (window.innerWidth >= 945),
        }
        this.getImageClass = this.getImageClass.bind(this);
        this.getTextClass = this.getTextClass.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
      }
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }

      getImageClass() {
            return this.state.large ? "my-pic left-large" : "small-pic left-small "
      }

      getTextClass() {
            return this.state.large ? "about-me-text right-large about-me-font" : "right-small small-text about-me-font"
      }

      updateDimensions = () => {
        this.setState({large: window.innerWidth >= 945})
      };
    render() { 
        return (
         <div className="about-me">

            <img className={this.getImageClass()} src={myPic} alt={""}/>

            
            <div className={this.getTextClass()}>
              <div className="inner-text">  
                {"I am Antonio Aldama, currently a software developer at "}
                <a href="https://microsoft.com">{"Microsoft"}</a>
                {". I am trilingual, love coding, solving problems, and am always looking for a challenge. "}
                {"I coded this website using ReactJS to share more about myself. I have worked in full-stack, "} 
                {"frontend and backend development. I have experience working at Amazon and Microsoft."}
              <div>
                <Resume/>
              </div>
            </div>
            

            
            </div>


         </div>
        );
    }
}
 
AboutMe.propTypes = {
    event: PropTypes.object.isRequired,
}
export default AboutMe;