import React from 'react';
import "./css/educationCard.css";
import PropTypes from 'prop-types';
import ucsdWhite from "./images/UCSD_Logo.png";

class EducationCard extends React.Component {
    
    constructor() {
        super();
        this.state = {
            large: (window.innerWidth >= 906),
        }
        this.getEducationCard = this.getEducationCard.bind(this);
        this.getImgClass = this.getImgClass.bind(this);
        this.getTextClass = this.getTextClass.bind(this);
        this.getTitle = this.getTitle.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
      }
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }

    updateDimensions = () => {
        this.setState({large: window.innerWidth >= 400})
      };

    getImgClass() {
        return (this.state.large) ? "education-pic-large" : "education-pic-small";
    }

    getTextClass() {
        return (this.state.large) ? "education-text-large" : "education-text-small";
    }

    getTitle() {
        if (!this.state.large) { return; }
        return (
            <div className="education-main-title center-content">
                {this.props.education.school}
            </div> 
        );
    }

    getEducationCard() {
        

            return (<div className={"education-card"}>
            
            
            
            <a href={this.props.education.url}>
                    <img className={this.getImgClass()} src={this.props.education.profile} alt={this.props.education.school}/>
            </a>
            <div className="education-box"> 
                <div className={this.getTextClass()}>
                    {this.getTitle()}
                    {this.props.education.titles.map((description) => <div className="education-text-container">
                        <div className="d-inline education-text-title">
                            {description.title + " - "}
                        </div> 
                        <div className="d-inline">
                            
                            {description.title === "Honors" ? (
                                <em>{description.description}</em>
                            ) : (
                                description.description
                            )}
                            
                        </div>
                    </div>)}
                </div>
            </div>
        </div>);

    }
    render() { 
        return(
            <div>
                {this.getEducationCard()}
            </div>
        );
        
        
    }

}
 
EducationCard.propTypes = {
    education: PropTypes.object.isRequired,
}
export default EducationCard;