import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import HomePage from './HomePage';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/utility.css";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA-IvwpajQX5_TEsxZrn8Cs-5T5O3n2yJc",
  authDomain: "presentation-project-76401.firebaseapp.com",
  projectId: "presentation-project-76401",
  storageBucket: "presentation-project-76401.appspot.com",
  messagingSenderId: "653252050557",
  appId: "1:653252050557:web:e8ed3919d9f0ab3ff27b14",
  measurementId: "G-9JEB2QGD92"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

ReactDOM.render(
  
  <React.StrictMode>
    <HomePage />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
