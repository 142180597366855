import React, { createRef, useRef } from "react";
import Navbar from "./navbar";
import Timeline from "./timeline";
import Event from "./event";
import Footer from "./homepageSegments/footer.jsx";
import AboutMe from "./homepageSegments/aboutMe.jsx";
import "./css/homepage.css";
import amazonLogo from "./images/amazon-logo.png";
import fanLogo from "./images/fanSilverImage.png";
import microsoftLogo from "./images/microsoft-logo.jpg";
import fanSilverDemo from "./videos/CompleteCSD.mp4";
import EducationCard from "./educationCard";
import ucsdBack from "./images/ucsd-background.jpg";
import ucsdProf from "./images/ucsd-profile.jpg";
import workoutBuddyDemo from "./videos/WorkoutBuddyDemo.mov"
import ProjectCard from "./projectCard";

/**
 * To start run 'npm start' in terminal. Then open chrome on port 3000. localhost:3000
 * To update it on the web open netlify. Run `npm run-script build` command. Then upload the build folder.
 * https://www.netlify.com/blog/2016/07/22/deploy-react-apps-in-less-than-30-seconds/
 * https://app.netlify.com/sites/serene-borg-c8d308/deploys
 * I got my domain with google sites.
 * Then look at presentation website folder and upload build. 
 */
class HomePage extends React.Component {
  constructor() {
    super();
    this.state = {
      large: window.innerWidth >= 700,
      events: [
        {
          date: "September 2023 - present",
          title: "Software Engineer",
          descriptionPoints: ["Reduced security-vulnerable fabric owner privilege requests by 46% throughout Azure Compute Node Services by developing and implementing Testing-In-Production commandlets.", 
          "Enhanced developer experience with Testing-In-Production (TIP) by seamlessly integrating TIP logic into existing node commandlets, resulting in more efficient and streamlined workflows.",
          "Investigated, mitigated or resolved over 30 Sev2 incidents as designated responsible individual to maintain over 4.4 million nodes in Azure fleet healthy.",
          "Fixed security vulnerabilities in our codebase, solidifying our team’s perfect security score."],
          companyUrl: "https://microsoft.com",
          companyName: "Microsoft",
          src: microsoftLogo,
        },
        {
          date: "summer 2022",
          title: "Software Engineer Intern",
          descriptionPoints: ["Automated lookup, and inclusion, of external packages into Azure Orchestration’s cloud engine using C#, XML, and PowerShell", 
          "Reduced development cycle for partner teams publishing packages within Azure by three engineering hours",
          "Streamlined update process for internal teams deploying to Azure",
          "Implemented unit and integration tests to validate my feature"],
          companyUrl: "https://microsoft.com",
          companyName: "Microsoft",
          src: microsoftLogo,
        },
        {
          date: "summer 2021",
          title: "Amazon Propel Program Intern",
          descriptionPoints: [
            "Developed accessible and responsive website for Amazon’s internal search team",
            "Sped up development in website eightfold",
            "Built search engine’s frontend using ReactJS",
            "Connected website’s frontend with search backend using Amazon Kendra",
            "Expanded search engine’s backend search functionality using Java",
          ],
          companyUrl: "https://amazon.com",
          src: amazonLogo,
          companyName: "Amazon",
        },
        {
          date: "2019",
          title: "Volunteer",
          descriptionPoints: [
            "Developed a prototype application to manage inventory for FAN Silver Shop",
          ],
          companyUrl: "#",
          companyName: "FAN Silver Shop",
          src: fanLogo,
          demo: fanSilverDemo,
        },
      ],
      educations: [
        {
          date: "2019 - 2023",
          gpa: "3.985",
          majorGpa: "4.0",
          mainTitle: "Bachelor of Science",
          titles: [
            {
              title: "B.S.",
              description:
                "Cognitive Science with Specialization in Machine Learning and Neural Computation",
            },
            {
              title: "Honors",
              description: "magna cum laude",
            },
          ],
          background: ucsdBack,
          profile: ucsdProf,
          url: "https://ucsd.edu/",
          school: "University of California, San Diego",
        },
      ],
      projects: [
        {
          title: "Movie Rating Chatbot",
          description: ["Long flights often left me guessing which inflight movie to watch. Because the inflight catalogue is limited, and lacks reviews or descriptive plot summaries, I often found myself ", 
          "stopping a movie halfway through. To help me make better choices, I developed a Facebook Messenger chatbot that leverages Alaska Airlines’ free inflight messages to provide me with up-to-date reviews. "],
        },
        {
          title: "Workout Buddy App",
          description: ["Whenever I worked out, I found myself juggling between setting timers on my Apple Watch and keeping mental tabs on the set I was on. To streamline this process, I developed a WatchOS app using ",
          "Swift that automatically tracks the number of sets, rest time between sets, and my heart rate. This app transformed my workout experience by providing an automated, hands-free way to manage these variables."],
          demo: workoutBuddyDemo

        },
      ]
    };

    this.getCards = this.getCards.bind(this);
    this.getEducation = this.getEducation.bind(this);
    this.getCompanies = this.getCompanies.bind(this);
    this.getProjects = this.getProjects.bind(this);
  }

  updateDimensions = () => {
    console.log(window.innerWidth);
    console.log(window.innerHeight);
    this.setState({ large: window.innerWidth >= 700 });
  };
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async getBigTechNames() {
    //Select Big Tech companies
    const sparql = `
      SELECT DISTINCT ?item ?itemLabel WHERE {
          SERVICE wikibase:label { bd:serviceParam wikibase:language "en". }
          {
            SELECT DISTINCT ?item WHERE {
                ?item p:P361 ?statement0.
                ?statement0 (ps:P361) wd:Q30748112.
                ?item p:P361 ?statement1.
                ?statement1 (ps:P361/(wdt:P279*)) wd:Q242345.
           }
           ORDER BY ?item ASC(?itemLabel)
          }
        }
  `;
    const wbk = require("wikibase-sdk")({
      instance: "https://query.wikidata.org",
      sparqlEndpoint: "https://query.wikidata.org/sparql",
    });
    const url = wbk.sparqlQuery(sparql);
    console.log(url);
    try {
      const response = await fetch(url);
      const json = await response.json();
      console.log(json);
      console.log(json.results.bindings.map((item) => item.itemLabel.value));

      if (!response.ok) {
        throw Error(response.statusText);
      }
      return json.results.bindings.map((item) => item.itemLabel.value);
    } catch (error) {
      console.log(error);
    }
  }
  async getSP500names() {
    //Select SP500 companies not part of big tech
    const sparql = `
        SELECT DISTINCT ?item ?itemLabel WHERE {
            SERVICE wikibase:label { bd:serviceParam wikibase:language "en". }
            {
              SELECT DISTINCT ?item WHERE {
                ?item p:P361 ?statement0.
                ?statement0 (ps:P361/(wdt:P279*)) wd:Q242345.
                MINUS {
                    ?item p:P361 ?statement1.
                    ?statement1 (ps:P361/(wdt:P279*)) wd:Q30748112.
                }
             }
             ORDER BY ?item ASC(?itemLabel)
            }
          }
    `;
    const wbk = require("wikibase-sdk")({
      instance: "https://query.wikidata.org",
      sparqlEndpoint: "https://query.wikidata.org/sparql",
    });
    const url = wbk.sparqlQuery(sparql);
    console.log(url);
    try {
      const response = await fetch(url);
      const json = await response.json();
      console.log(json);
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return json.results.bindings.map((item) => item.itemLabel.value);
    } catch (error) {
      console.log(error);
    }
  }

  getCards() {
    return this.state.events.map((event, index) => (
      <Event key={index} event={event} />
    ));
  }

  getEducation() {
    return this.state.educations.map((education, index) => (
      <EducationCard key={index} education={education} />
    ));
  }

  getProjects() {
    return this.state.projects.map((project, index) => (
        <ProjectCard key={index} project={project} />
      
    ));
  }

  async getCompanies() {
    return [
      "Netflix",
      "Alphabet Inc.",
      ...(await this.getBigTechNames()),
      ...(await this.getSP500names())
        .filter((word) => word !== "Alphabet Inc.")
        .sort(),
    ];
  }

  render() {
    //const companies = [...this.getBigTechNames(), ...this.getSP500names()]
    //console.log(this.getBigTechNames())
    console.log(this.getCompanies());

    return (
      <div className="">
        <Navbar companies={this.getCompanies()} />
        <div className="page-background">
          <h2 id="about"> About me </h2>
          <div className={this.state.large ? "cards" : "cards small"}>
            <AboutMe />
          </div>
          <h2 id="experience"> Experience </h2>
          <div className={this.state.large ? "cards" : "cards small"}>
            {this.getCards()}
          </div>
          <h2 id="education"> Education </h2>
          <div className={this.state.large ? "cards" : "cards small"}>
            {this.getEducation()}
          </div>
          <h2 id="projects"> Projects </h2>
          <div className={this.state.large ? "cards" : "cards small"}>
            {this.getProjects()}
          </div>
          
          
          <Footer />
        </div>
      </div>
    );
  }
}

export default HomePage;
