import React from 'react';
import PropTypes from 'prop-types';
import "./css/resume.css";
import "./css/utility.css";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faDownload } from "@fortawesome/free-solid-svg-icons";
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';

class Resume extends React.Component {
    constructor() {
        super();
        this.state = {
            large: (window.innerWidth >= 661),
            email: "mailto:antonio.aldama@icloud.com?subject=[Company] Interested &body=Hello Antonio, %0d%0a %0d%0a We at [Company] are interested in your skills. %0d%0a %0d%0a Regards, %0d%0a [Name]"
        }
    }

    render() { 
        return (
            

        <div className="resume-box">
            <Button href="/files/Antonio_Aldama_CV.pdf" download className="resume-button" variant="contained" startIcon={<DownloadIcon/>}>
            <div className="resume-text">
                Download CV
            </div>
            </Button>
        </div>
         
        );
    }
}
 
/*
<button className="center-content resume-box">
            <FontAwesomeIcon icon={faDownload} className="download-icon"/>
             Download CV
         </button>
*/

export default Resume;