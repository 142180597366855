import React from 'react';
import PropTypes from 'prop-types';
import Modal from "react-bootstrap/Modal";
import "./css/emailPopover.css";
import "./css/utility.css";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CompanyAutosuggest from "./companyAutosuggest";
import Button from '@mui/material/Button';
import CreateIcon from '@mui/icons-material/CreateOutlined';
import ClickAwayListener from '@mui/base/ClickAwayListener';

class EmailPopover extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        companies: [],
        open: false,
        email: "mailto:antonio.aldama@icloud.com?subject=[Name] from [Company] &body=Hello Antonio, %0d%0a %0d%0a %0d%0a %0d%0a Regards, %0d%0a [Name]",
        companyName: "",
        employerName: "",
        enabled: false, 
      }
      this.props.companies.then((companies) => {this.setState({companies: companies})});
      this.handleClickAway = this.handleClickAway.bind(this);
      this.getPopoverName = this.getPopoverName.bind(this);
      this.handleShow = this.handleShow.bind(this);
      this.updateCompanyName = this.updateCompanyName.bind(this);
      this.updateEmployerName = this.updateEmployerName.bind(this);
      this.getEnabled = this.getEnabled.bind(this);
      this.getEmail = this.getEmail.bind(this);
    }

    handleClickAway() {
      this.setState({open: false});
      
    }

    handleShow() {
      this.setState({open: !this.state.open}); 
    }

    getPopoverName() {
      return (this.state.open === true) ? 
      ((this.state.enabled) ? "mail-popover d-inline enabled" : "mail-popover d-inline")
      : "hidden";
    }

    updateCompanyName(companyName) {
      this.setState({companyName, enabled: (companyName !== "" && this.state.employerName !== "")}); 
      this.getEnabled();
    }

    updateEmployerName(employerName) {
      this.setState({employerName: employerName.target.value, enabled: (this.state.companyName !== "" && employerName.target.value !== "")}); 
      this.getEnabled();
    }

    getEnabled() {
      /*this.setState({enabled: (this.state.companyName !== "" && this.state.employerName !== "")});*/
    }

    getEmail() {
      var email = this.state.email
      email = email.replaceAll("[Company]", this.state.companyName);
      email = email.replaceAll("[Name]", this.state.employerName);
      return email;
    }

    render() { 
      //Note how promise values are accessed
      //this.props.companies.then((company) => {return company})
      //console.log(this.state);
      return (
            <React.Fragment>
              
              
              <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={this.handleClickAway}
    >
        <div>
        <button onClick={this.handleShow} className="navbar-mail-icon-2 d-inline">
                        <FontAwesomeIcon icon={faEnvelope} size="2x"/>
              </button>
        <div className={this.getPopoverName()}> 
                  
                  <CompanyAutosuggest
                    companies={this.state.companies}
                    updateCompanyName={this.updateCompanyName}
                  />

                  <input className="input-field" name="first name" placeholder="First name" onChange={this.updateEmployerName}>

                  </input>

                  <Button href={this.getEmail()} fullWidth={true} variant="outline" color="primary" startIcon={<CreateIcon color="white"/>}>
                    Compose
                  </Button>
              </div>
        </div>
        
              
    </ClickAwayListener>

              
            </React.Fragment>
            
        );
    }
}
/*
<div className="padding-bottom-1">
                    Company name:
                  </div>
<Modal className="modal" show={this.props.modalShown} onHide={this.props.handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <button variant="secondary" onClick={this.props.handleClose}>
            Close
          </button>
          <button variant="primary" onClick={this.props.handleClose}>
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
*/
EmailPopover.propTypes = {
    handleClose: PropTypes.func.isRequired,
    modalShown: PropTypes.bool.isRequired,
    companies: PropTypes.object.isRequired
}
export default EmailPopover;