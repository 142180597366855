import React from 'react';
import "../css/footer.css";
import "../css/utility.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

class Footer extends React.Component {
    constructor() {
        super();
        this.state = {
            large: (window.innerWidth >= 661),
            email: "mailto:antonio.aldama@icloud.com?subject=[Company] Interested &body=Hello Antonio, %0d%0a %0d%0a We at [Company] are interested in your skills. %0d%0a %0d%0a Regards, %0d%0a [Name]"
        }
    }

    render() { 
        return (
         <div className="footer center-content">
            
            
         </div>
        );
    }
}
 
{/*
            
            <div className="logos center-content">
                    {/*
                    <a href="https://www.linkedin.com/in/antoalda" name="linkedin" className="footer-linkedin-icon d-inline">
                        <FontAwesomeIcon icon={faLinkedin} size="3x"/>
                    </a>
                    
                    <a href={this.state.email} className="footer-mail-icon d-inline">
                        <FontAwesomeIcon icon={faEnvelope} size="2x"/>
                    </a>    
                    
                    
                    </div>
            
            */}

export default Footer;