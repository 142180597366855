import React from 'react';
import PropTypes from 'prop-types';
import "./css/timeline.css"

class Timeline extends React.Component {
    constructor() {
        super();
        this.getTimeline = this.getTimeline.bind(this);
    }
    getTimeline() {
        return this.props.events.map((event) => 
        <div className="line-segment">{event.title}</div> 
        );
    }
    render() { 
        return (
            <div className="timeline">
                <div> timeline </div>
                {this.getTimeline()}
            </div>
            
        );
    }
}

Timeline.propTypes = {
    events: PropTypes.array.isRequired,
}
 
export default Timeline;